import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dates-and-times"
    }}>{`Dates and Times`}</h1>
    <p>{`In this article, we have laid out some general guidelines for displaying dates and times. All date
and time formats included in this article have been approved and are supported by XO and the
globalization team.`}</p>
    <p>{`We have included multiple formatting options and guidelines here for the following reasons:`}</p>
    <ol>
      <li parentName="ol">{`To give teams working on a variety of use cases the flexibility to choose a format that works
best for their feature.`}</li>
      <li parentName="ol">{`To reduce cognitive load on the user by using easily scannable and understandable formats.`}</li>
      <li parentName="ol">{`To keep our Workday voice conversational and friendly.`}</li>
    </ol>
    <p>{`Note: For some teams, ISO formatting is the standard for displaying dates. However, in Workday's UI,
we only use ISO formatting in certain instances (as noted below in this article).`}</p>
    <h2 {...{
      "id": "our-recommendations-at-a-glance"
    }}>{`Our Recommendations at a Glance`}</h2>
    <h3 {...{
      "id": "write-out-dates-whenever-possible"
    }}>{`Write Out Dates Whenever Possible`}</h3>
    <p>{`There are several supported ways to display dates in Workday. Here are our quick recommendations:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use a written-out, non-numerical date format whenever possible to improve clarity and readability.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Write dates as cardinal numbers (January 2), not ordinal numbers (January 2nd).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When writing a date that includes the day of the week, if you fully spell out the day of the week
(instead of abbreviating), also fully spell out the month.`}</p>
      </li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Wednesday, January 9, 2018`}</li>
        <li parentName="ul">{`Wed, Jan 9, 2018`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Weds, January 09/18`}</li>
        <li parentName="ul">{`Wednesday, Jan. 9th, '18`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "use-numerical-formats-when-low-on-space"
    }}>{`Use Numerical Formats When Low on Space`}</h3>
    <ul>
      <li parentName="ul">{`Only use a numerical format for dates if you have a compelling reason (like a lack of space in
table). Workday currently supports an 8-digit format.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`01/09/2018`}</li>
        <li parentName="ul">{`2018-01-09 (ISO formatting)`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`1/9/2018`}</li>
        <li parentName="ul">{`18-1-9`}</li>
      </ul>
    </Dont>
    <p>{`For more details and specifics, as well as examples of date formatting, read on.`}</p>
    <h3 {...{
      "id": "examples-of-supported-us-standalone-dates-and-times"
    }}>{`Examples of Supported U.S. Standalone Dates and Times`}</h3>
    <p>{`The following table outlines many standalone date and time variations you might need to use, and how
they should be formatted:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Numerical`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Non-Numerical`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Standalone Times`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05:30 PM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24-hour Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13:00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Ranges`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11:00 AM–09:00 PM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date, Month, and Year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12/02/2018 `}<br />{` 2018-12-02 (ISO)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January 06, 2018 `}<br />{` Feb 7, 2018`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of Week, Date, Month, and Year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, 06/10/2018 `}<br />{` Tues, 06/10/2018`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, January 6, 2018 `}<br />{` Tue, Feb 7, 2018`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Time `}<br />{` (12 hour)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018 10:30 AM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`June 23, 2018 10:30 AM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Time (24 hour)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018 14:30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Day of Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, 06/23/2018 10:30 AM `}<br />{` Tue, 06/23/2018 10:30 AM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, June 23, 2018 10:30 AM `}<br />{` Tue, Jun 23, 2018 10:30 AM`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "how-to-format-ranges-of-dates"
    }}>{`How to Format Ranges of Dates`}</h2>
    <p>{`For date ranges, you'll follow all the same rules as above (see Our Recommendations at a Glance),
with a few additions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In order to keep date and time ranges as short and clear as possible, only include the minimum
necessary information.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the date range is in the same year, don't repeat the year.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the date range is in the same month, don't repeat the month.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`However, if time is included in a date range, do include the year for both dates listed.`}</p>
      </li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Jun 3–24, 2018`}</li>
        <li parentName="ul">{`Jun 3–Aug 17, 2018`}</li>
        <li parentName="ul">{`Jun 3, 2018–Aug 17, 2019`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Jun 3–Jun 24, 2018`}</li>
        <li parentName="ul">{`Jun 3, 2018–Aug 17, 2018`}</li>
      </ul>
    </Dont>
    <p>{`To connect ranges, use an EN dash with no spaces on either side (for example, Jun 3–Jun 24, 2018).`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Jun 3–Jun 24, 2018`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Jun 3 – Jun 24, 2018`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "long-or-busy-date-ranges"
    }}>{`Long or Busy Date Ranges`}</h3>
    <ul>
      <li parentName="ul">{`If you find you need a long or visually busy range (such as Tue, 06/23/2018 10:30 AM–Thu,
06/24/2018 01:15 PM PST), try to use other cues or elements, such as headers, on the page to break
up the date range. Put repeated information at the top of the page or the beginning of a block of
information. Use headers to include information that applies to the information below.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Tue, 06/23/2018–Thu, 06/24/2018:`}<br parentName="li"></br>
          {`10:30 AM–11:30 AM PST Session 1`}<br parentName="li"></br>
          {`12:30 PM–1:30 PM PST Session 2`}</li>
      </ul>
    </Do>
    <h3 {...{
      "id": "examples-of-supported-us-date-ranges"
    }}>{`Examples of Supported U.S. Date Ranges`}</h3>
    <p>{`The following table outlines most date and time range variations you might need to use, and how they
should be formatted:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Numerical`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Non-Numerical`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Same Month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018–06/24/2018`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jun 3–24, 2018`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Same Year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018–8/17/2018`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jun 3–Aug 17, 2018`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Different Years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12/31/2018–01/01/2019`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jun 23, 2018–Jun 24, 2019`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Time `}<br />{` (12 hour)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018 10:30 AM–06/24/2018 01:15 PM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jun 23, 2018 10:30 AM–Jun 24, 2019 01:15 PM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Time (24 hour)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06/23/2018 10:30–06/24/2018 14:15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Day of Week (Abbreviated)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tue, 06/23/2018 10:30 AM–Thu, 06/24/2018 01:15 PM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tue, Jun 23, 2018 10:30 AM–Thu, Jun 24, 2018 01:15 PM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`With Day of Week (Spelled Out)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, 06/23/2018 10:30 AM–Thursday, 06/24/2018 01:15 PM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday, June 23, 2018 10:30 AM–Thursday, June 24, 2018 01:15 PM`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "time-durations"
    }}>{`Time Durations`}</h2>
    <p>{`Use the following preferred formats when writing out durations of time:`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`6 hours, 15 minutes`}</li>
        <li parentName="ul">{`1 hour, 1 minute`}</li>
        <li parentName="ul">{`6 hrs, 15 min`}</li>
        <li parentName="ul">{`1 hr, 1 min`}</li>
      </ul>
    </Do>
    <h2 {...{
      "id": "month-and-day-abbreviations"
    }}>{`Month and Day Abbreviations`}</h2>
    <p>{`When abbreviating months and days of the week, use the table below. Do not include a period after
the abbreviation.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Day of the Week`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Month`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Monday → Mon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`January → Jan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tuesday → Tue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`February → Feb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wednesday → Wed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`March → Mar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thursday → Thu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`April → Apr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Friday → Fri`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May → May`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Saturday → Sat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`June → Jun`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sunday → Sun`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`July → Jul`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`August → Aug`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`September → Sep`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`October → Oct`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`November → Nov`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`December → Dec`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "why-can-we-only-use-these-formats"
    }}>{`Why Can We Only Use These Formats?`}</h2>
    <p>{`In Workday, we have 2 separate user preferences that may affect date and time formatting: locale and
language.`}</p>
    <p>{`Workday supports more than 80 locales. Locale drives numerical date and time formatting (dates and
times written only in numbers, like 05/23/2019 or 23/05/2019).`}</p>
    <p>{`Language drives the formatting of written-out, non-numerical dates and times. For example, June 23,
2019 in French is written as 23 juin 2019. Workday supports around 30 languages.`}</p>
    <p>{`The locale and language will affect things like the order that days and months are presented in,
whether time is given in an AM/PM or 24-hour format, and more. Most translation and localization is
handled automatically through format masks, which change the way dates and times are displayed in
other locales or languages. Because this work is done automatically, we can only use date and time
formats that have been approved by the globalization team, so that dates and times can be easily
redisplayed for all of our worldwide customers.`}</p>
    <p>{`If you are not sure that your dates will be automatically masked or translated by Workday, use the
ISO formatting included in the example tables above.`}</p>
    <p>{`If you have more questions about how to format dates and times, or need to use a format not listed
here, please reach out to the content team (ux-product-content) to see if it can be supported.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      